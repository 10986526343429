import React from 'react';

import PrivateRoute from 'components/PrivateRoute';
import SavedSearchesPage from 'views/SavedSearchesPage';

export default () => (
  <PrivateRoute>
    <SavedSearchesPage />
  </PrivateRoute>
);
